import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['saveButton'];

  connect() {
    $(this.saveButtonTarget).tooltip('disable')
  }

  enableSave() {
    if (this.saveButtonTarget.disabled) {
      this.saveButtonTarget.disabled = false;
    }
    $(this.saveButtonTarget).tooltip('enable')
    $(this.saveButtonTarget).tooltip('show')
    $(this.saveButtonTarget).tooltip('disable')
  }
}
