import React, { Fragment, useEffect, useState } from "react";
import StepLayout from "../StepLayout"
import Grid from "@material-ui/core/Grid";
import I18n from "i18n-js";
import { makeStyles } from "@material-ui/core/styles";
import Paper from '@material-ui/core/Paper';
import PublicBtn from "../../../components/PublicBtn";
import TextField from "@material-ui/core/TextField";
import { ButtonBase, Link, Typography } from "@material-ui/core/";
import { Divider } from "semantic-ui-react";
import { setGlobalState } from "../../../state";
import { useLazyQuery } from "@apollo/react-hooks";
import { ALL_COMMUNITIES, ALL_PROPERTIES } from "../../../api";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddLocationOutlinedIcon from '@material-ui/icons/AddLocationOutlined';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import WizardTitle from "../WizardTitle";

const useStyles = makeStyles(theme => ({
    textfield: {
        '& input:valid + fieldset': {
            borderColor: 'black',
        },
        '& input:invalid + fieldset': {
            borderColor: 'black',
        },
        '& input:valid:focus + fieldset': {
            padding: '4px !important', // override inline-style
        },
    },
    horizontalDivisor: {
        borderBottom: '2px solid #eaeaea',
        opacity: '0.6',
        margin: '5% 0%'
    },
    MediaLogo: {
        width: '70%'
    },
    qrPaperButton: {
        padding: '5%'
    }
}));

function NewPropertyRequestStepContent({ previous, next, backdrop }) {
    const [communityId, setCommunityId] = useState(null);
    const [communityList, setCommunityList] = useState([]);
    const [communityOptions, setCommunityOptions] = useState([]);
    const [communitySelected, setCommunitySelected] = useState(null);
    const [disableButton, setDisableButton] = useState(true);
    const [loadingCommunityList, setLoadingCommunityList] = useState(false);
    const [loadingCommunityText, setLoadingCommunityText] = useState(I18n.t('modules.register.searching_message'));
    const [loadingIconCommunities, setLoadingIconCommunities] = useState(false);
    const [loadingIconProperties, setLoadingIconProperties] = useState(false);
    const [loadingPropertyText, setLoadingPropertyText] = useState(I18n.t('modules.register.searching_message'));
    const [openCommunity, setOpenCommunity] = useState(false);
    const [openCommunityList, setOpenCommunityList] = useState(false);
    const [openProperty, setOpenProperty] = useState(false);
    const [propertyId, setPropertyId] = useState(null);
    const [propertyList, setPropertyList] = useState([]);
    const [propertyOptions, setPropertyOptions] = useState([]);
    const [showError, setShowError] = useState(false);
    const classes = useStyles();
    const loadingCommunities = openCommunity && communityOptions.length === 0;
    const loadingProperties = openProperty && propertyOptions.length === 0;
    const [sendCommunityRequest, { dataCommunity, loadingCommunity }] = useLazyQuery(ALL_COMMUNITIES,
        {
            onCompleted: data => {
                setLoadingIconCommunities(false);
                if (data && data.communities) {
                    if (data.communities && data.communities.length > 0) {
                        setOpenCommunity(true);
                        setCommunityOptions(Object.keys(data.communities).map(key => data.communities[key]));
                    } else {
                        setLoadingCommunityText(I18n.t('modules.register.no_results'));
                        setCommunityList([]);
                    }
                } else {
                    setLoadingCommunityText(I18n.t('modules.register.no_results'));
                    return false;
                }
            }
        }
    );
    const [sendPropertyRequest, { dataProperty, loadingProperty }] = useLazyQuery(ALL_PROPERTIES,
        {
            onCompleted: data => {
                setLoadingIconProperties(false);
                if (data && data.communityProperties) {
                    if (data.communityProperties.length > 0) {
                        setOpenProperty(true);
                        setPropertyOptions(Object.keys(data.communityProperties).map(key => data.communityProperties[key]));
                    } else {
                        setLoadingPropertyText(I18n.t('modules.register.no_results'));
                        setPropertyList([]);
                    }
                } else {
                    setOpenProperty(false);
                    return false;
                }
            }
        }
    );

    function registerManualPage() {
        setGlobalState({
            communityId: communityId,
            propertyId: propertyId
        });
        next({ choice: 'manual' });
    }

    function registerQrPage() {
        next({ choice: 'qr' });
    }

    useEffect(() => {
        let active = true;

        if (!loadingCommunities) {
            return undefined;
        }

        return () => {
            active = false;
        };
    }, [loadingCommunities]);

    useEffect(() => {
        let active = true;

        if (!loadingProperties) {
            return undefined;
        }

        return () => {
            active = false;
        };
    }, [loadingProperties]);

    useEffect(() => {
        if (!openCommunity) {
            setCommunityOptions([]);
        }
    }, [openCommunity]);

    useEffect(() => {
        if (!openProperty) {
            setPropertyOptions([]);
        }
    }, [openProperty]);

    function loadCommunityList(community_name) {
        if(community_name.length >= 3){
            setLoadingCommunityText(I18n.t('modules.register.searching_message'));
            setLoadingIconCommunities(true);
            sendCommunityRequest({ variables: { input: { name: community_name } } });
        }else{
            setOpenCommunity(false);
        }
    }

    function loadPropertyList(property_name) {
        if(property_name.length >= 1){
            setLoadingPropertyText(I18n.t('modules.register.searching_message'));
            setLoadingIconProperties(true);
            sendPropertyRequest({ variables: { communityId: communityId, propertyInput: { name: property_name.toLowerCase() } } });
        }else{
            setOpenProperty(false);
        }
    }

    function onChangeCommunity(event, value) {
        setGlobalState({
            communityId: value.id
        });
        setCommunityId(value.id);
    }

    function onChangeProperty(event, value) {
        setGlobalState({
            propertyId: value.propertyId
        });
        setPropertyId(value.propertyId);
        setDisableButton(false);
    }

    return (
        <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={12} md={7}>
                <WizardTitle number={2} />
                <Autocomplete
                    id="community-autocomplete"
                    className={classes.textfield}
                    open={openCommunity}
                    onOpen={() => {
                        setOpenCommunity(true);
                    }}
                    onClose={() => {
                        setOpenCommunity(false);
                    }}
                    onChange={onChangeCommunity} // prints the selected value
                    getOptionSelected={(option, value) => option.name === value.name}
                    getOptionLabel={option => (option.name + ' - ' + option.address)}
                    options={communityOptions}
                    loading={loadingCommunities}
                    noOptionsText={I18n.t('modules.register.no_results')}
                    loadingText={loadingCommunityText}
                    renderOption={(option) => (
                        <Fragment>
                            <span ><AddLocationOutlinedIcon /></span>
                            {'\u00A0'}
                            {'\u00A0'}
                            {option.name + ' - ' + option.address}
                        </Fragment>
                    )}
                    renderInput={params => (
                        <TextField
                            {...params}
                            label={I18n.t('modules.register.community_name')}
                            variant="outlined"
                            onChange={event => {
                                loadCommunityList(event.target.value);
                            }}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {loadingIconCommunities ? <CircularProgress color="inherit" size={20} /> : null}
                                    </React.Fragment>
                                ),
                            }}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12} md={7}>
                <Autocomplete
                    id="property-autocomplete"
                    className={classes.textfield}
                    open={openProperty}
                    onOpen={() => {
                        setOpenProperty(true);
                    }}
                    onClose={() => {
                        setOpenProperty(false);
                    }}
                    onChange={onChangeProperty}
                    getOptionSelected={(option, value) => option.name === value.PropertyName}
                    getOptionLabel={option => option.propertyName}
                    options={propertyOptions}
                    noOptionsText={I18n.t('modules.register.no_results')}
                    loading={loadingProperties}
                    loadingText={loadingPropertyText}
                    renderOption={(option) => (
                        <Fragment>
                            <span ><HomeOutlinedIcon /></span>
                            {'\u00A0'}
                            {'\u00A0'}
                            {option.propertyName}
                        </Fragment>
                    )}
                    renderInput={params => (
                        <TextField
                            {...params}
                            variant="outlined"
                            label={I18n.t('modules.register.external_number')}
                            onChange={event => {
                                loadPropertyList(event.target.value);
                            }}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {loadingIconProperties ? <CircularProgress color="inherit" size={20} /> : null}
                                    </React.Fragment>
                                ),
                            }}
                        />
                    )}
                />
            </Grid>

            <Grid item xs={12} md={7}>
                <Divider />
            </Grid>
            <Grid item xs={12} md={7}>
                <PublicBtn disabled={disableButton} label={I18n.t('modules.register.next')} onClick={registerManualPage} >Next</PublicBtn>
            </Grid>

            <Grid item container direction="column" xs={12} md={7}>
                <Divider className={classes.horizontalDivisor} />
                <ButtonBase focusRipple={true} onClick={registerQrPage}>
                    <Paper className={classes.qrPaperButton} elevation={2} >
                        <Grid item alignItems="center" justifyContent="center" container direction="row">
                            <Grid item xs={6}>
                                <Typography variant="body1" >
                                    <label>{I18n.t('modules.register.qr_registry_message')}</label>
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <img className={classes.MediaLogo} src='/verification-image.png' />
                            </Grid>
                        </Grid>
                    </Paper>
                </ButtonBase>
            </Grid>
        </Grid >
    )
}

function NewPropertyRequestStep({ previous, next , backdrop }) {

    return (
        <StepLayout previous={previous} next={next} numberStepLabel={2} numberStep={2} StepContent={NewPropertyRequestStepContent} />
    )
}

export default NewPropertyRequestStep;
