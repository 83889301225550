import { Controller } from 'stimulus';
import AutoNumeric from 'autonumeric'

const AUTONUMERIC_FORMAT = { currencySymbol: '$', currencySymbolPlacement: 'p', digitGroupSeparator: '.', decimalCharacter: ',', decimalPlaces: 0, emptyInputBehavior: 'focus' }
const MIN_YEAR = 2024;
const MIN_MONTH = 6;


export default class extends Controller {
  static targets = ['debit', 'credit'];

  disableOther(event) {
    let value = AutoNumeric.getAutoNumericElement(event.target).getNumber();
    if (value == 0) {
      this.debitTarget.value = ''
      this.debitTarget.disabled = false
      this.creditTarget.value = ''
      this.creditTarget.disabled = false
    }
    else {
      if (event.target == this.debitTarget) {
        AutoNumeric.getAutoNumericElement(this.creditTarget).set(0);
        this.creditTarget.value = ''
        this.creditTarget.disabled = true
      } else {
        AutoNumeric.getAutoNumericElement(this.debitTarget).set(0);
        this.debitTarget.value = ''
        this.debitTarget.disabled = true
      }
    }
    this.sumTotals()
  }

  sumTotals() {
    let allDebits = document.querySelectorAll('[id^="accounting_subaccounts_"][id$="_debit"]');
    let allCredits = document.querySelectorAll('[id^="accounting_subaccounts_"][id$="_credit"]');
    let totalDebit = 0;
    let totalCredit = 0;
    let numericTotalDebit = AutoNumeric.getAutoNumericElement(document.getElementById('totals-debit')) || new AutoNumeric(document.getElementById('totals-debit'), AUTONUMERIC_FORMAT);
    let numericTotalCredit = AutoNumeric.getAutoNumericElement(document.getElementById('totals-credit')) || new AutoNumeric(document.getElementById('totals-credit'), AUTONUMERIC_FORMAT);

    let saveButton = document.getElementById('save-new-opening-entries')
    allDebits.forEach(newEntryInput => {
      totalDebit += AutoNumeric.getAutoNumericElement(newEntryInput).getNumber();
    })

    allCredits.forEach(newEntryInput => {
      totalCredit += AutoNumeric.getAutoNumericElement(newEntryInput).getNumber();
    })
    numericTotalDebit.set(totalDebit);
    numericTotalCredit.set(totalCredit);
    if (this.checkSelectors() && (totalDebit == totalCredit) && (totalDebit > 0 && totalCredit > 0)) {
      saveButton.classList.remove('disabled')
      $('#save-container').tooltip('destroy')

    } else {
      saveButton.classList.add('disabled')
      $('#save-container').tooltip()
    }
  }

  checkSelectors() {
    let yearSelector = document.getElementById('year-selector-hidden-value');
    let monthSelector = document.getElementById('month-selector-hidden-value');
    if (parseInt(yearSelector.value) >= MIN_YEAR &&  parseInt(monthSelector.value) >= MIN_MONTH) {
      document.getElementById('warning-selector').style.display = 'none'
      return true
    } else {
      document.getElementById('warning-selector').style.display = 'block'
      return false
    }
  }
}
