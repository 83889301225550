import { Controller } from 'stimulus'
import I18n from "i18n-js";

export default class extends Controller {
  static targets = [ 'form', 'phoneInput', 'modal', 'template', 'successTemplate', 'errorTemplate', 'submitButton', 'submitButtonSpinner', 'submitButtonText', 'contactPhoneError' ]

  connect() {
    this.hideSpinner()
    this.formTarget.addEventListener('submit', event => {
      event.preventDefault();
      event.stopPropagation();

      if (!this.validatePhoneInput()) {
        return;
      }

      this.disablePhoneInputError()
      this.disableSubmitButton()
      this.showSpinner()

      const body = new FormData(event.target)
      body.append('phone', this.phoneInputTarget.value)

      fetch(event.target.action, {
        method: 'POST',
        body: body,
        headers: {
          'Accept': 'application/json',
        }
      }).then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      }).then(body => {
        this.hideSpinner()
        if (body.status === true) {
          this.modalTarget.innerHTML = this.successTemplateTarget.innerHTML;
        } else {
          let errorHTML = this.errorTemplateTarget.innerHTML;
          errorHTML = errorHTML.replace('{{errorTitle}}', body.error_title);
          errorHTML = errorHTML.replace('{{errorMessage}}', body.error_message);
          this.modalTarget.innerHTML = errorHTML;
        }
        $(this.modalTarget).modal()
      }).catch(error => {
        this.hideSpinner()
      })
    });
  }

  disableSubmitButton() {
    this.submitButtonTarget.disabled = true
  }

  showSpinner() {
    this.submitButtonTextTarget.style.display = 'none'
    this.submitButtonSpinnerTarget.style.display = 'flex'
  }

  hideSpinner() {
    this.submitButtonTextTarget.style.display = 'block'
    this.submitButtonSpinnerTarget.style.display = 'none'
  }

  cancelModal(event) {
    $(event.currentTarget).closest('.modal-dialog').remove()
  }

  disablePhoneInputError(){
    const phoneTarget = this.phoneInputTarget;
    const errorElement = this.contactPhoneErrorTarget;
    phoneTarget.classList.remove('error-outline');
    errorElement.style.display = 'none';
  }

  validatePhoneInput(){
    const phoneTarget = this.phoneInputTarget;
    if (phoneTarget.value.length < 5) {
      const errorElement = this.contactPhoneErrorTarget;
      const errorMessage = I18n.t('views.insurances.index.quote_request_phone_error');

      errorElement.textContent = errorMessage;
      errorElement.style.display = 'block';

      phoneTarget.classList.add('error-outline');

      return false;
    }
    return true;
  }
}
