import gql from "graphql-tag";
import { GraphQLEnumType } from "graphql";

export const COUNTRY = new GraphQLEnumType({
  name: "Country",
  values: {
    MEXICO: { value: "MEXICO" },
    CHILE: { value: "CHILE" },
  },
});

export const CREATE_USER_MUTATION = gql`
  mutation createUser(
    $firstName: String!
    $lastName: String!
    $country: Country!
    $authProvider: AuthProviderSignupData!
    $communityId: ID!
    $acceptedTos: Boolean
  ) {
    createUser(
      firstName: $firstName
      lastName: $lastName
      country: $country
      authProvider: $authProvider
      communityId: $communityId
      acceptedTos: $acceptedTos
    ) {
      id
      firstName
      lastName
      token
    }
  }
`;
