import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ "inputName", "inputNumberCard", "inputDateCard" ]

  validateNameFile() {
    let cardName = this.inputNameTarget.value
    this.inputNameTarget.value = cardName.replace(/[0-9]/g, '').replace(/[^a-zA-Z0-9 ]/g, '')
    this.buttonActive();
  }

  validateNumberFile() {
    let cardNumber = this.inputNumberCardTarget.value
    cardNumber = cardNumber.replace(/[^0-9]/g, '').replace(/[^a-zA-Z0-9]/g, '')
    this.inputNumberCardTarget.value = cardNumber.trim()
    this.buttonActive();
   }

   validateDateCardFile(){
    let cardDate = this.inputDateCardTarget.value
    cardDate = cardDate.replace(/[^0-9]/g, '').replace(/[^a-zA-Z0-9 ]/g, '')
    cardDate = cardDate.substr(0,4)
    let cardMonth = cardDate.substr(0,2)
    let cardYear = cardDate.substr(2,4)
    cardDate =  cardMonth + '/' + cardYear
    let currentTime = new Date()
    
    if (parseInt(cardMonth) > 12 || parseInt(cardYear) < parseInt(currentTime.getFullYear().toString().slice(-2))){
      cardDate="";
    }
    this.inputDateCardTarget.value = cardDate
    this.buttonActive();
   }

   buttonActive(){
    const buttonSubmitModal = document.getElementById('_field_card_detail_cvc_card_text')
    if(this.inputNameTarget.value.length >= 3 && this.inputNumberCardTarget.value.length >= 16 && this.inputDateCardTarget.value.length >= 4 ){
      buttonSubmitModal.removeAttribute('disabled', 'disabled')
    }else{
      buttonSubmitModal.setAttribute('disabled', 'disabled')
    }
   }
}
