import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['supplierSelector', 'supplierName', 'supplierDni', 'formDiv']

  displayForm() {
    if (this.supplierSelectorTarget.value == '0') {
      this.formDivTarget.classList.remove('hide')
    } else {
      this.formDivTarget.classList.add('hide')
    }
  }
}
