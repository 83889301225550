import { Controller } from 'stimulus';
import Highcharts from 'highcharts';

Highcharts.setOptions({
  chart: {
    style: {
      fontFamily: 'Montserrat',
      fontWeight: 500
    }
  },
  colors: ['#5A8CF9', '#544fc5', '#00e272', '#fe6a35', '#6b8abc', '#d568fb', '#2ee0ca', '#fa4b42', '#feb56a', '#91e8e12' ],
  lang: {
    months: [
      'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
      'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
    ],
    weekdays: [
      'Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'
    ],
    shortMonths: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
    decimalPoint: ',',
    thousandsSep: '.',
  },
  plotOptions: {
    bar: {
      borderRadius: 10
    },
    series: {
      events: {
        legendItemClick: function(e) {
          e.preventDefault();
        }
      }
    }
  },
  xAxis: {
    labels: {
      style: {
        fontSize: '10px'
      }
    }
  },
  yAxis: {
    labels: {
      style: {
        fontSize: '10px'
      }
    },
    title: {
      style: {
        fontSize: '12px'
      }
    }
  }
});

export default class extends Controller {
  connect() {
    this.frame = document.getElementById(this.element.id)
    this.chartData = JSON.parse(this.frame.dataset.chartData)
    if (this.frame.dataset.chartExtras) {
      this.chartExtras = JSON.parse(this.frame.dataset.chartExtras)
    }
    this.chartId = this.frame.dataset.chartId
    this.self = this
    this.renderChart()
  }

  renderChart() {
    const self = this
    let formattedChartData = {
      ...this.chartData,
      tooltip: {
        useHTML: true,
        formatter: function() {
          return self.tooltipFormatter(this)
        },
        shared: true,
        style: { fontSize: '12px' }
      }
    }
    Highcharts.chart(this.frame, formattedChartData);
  }

  tooltipFormatter(set) {
    if (this.chartId == 'funds') {
      return this.fundsTooltipFormatter(set)
    } else if (this.chartId == 'service-billings') {
      return this.serviceBillingTooltipFormater(set)
    } else {
      return this.defaultTooltipFormater(set)
    }
  }

  fundsTooltipFormatter(set) {
    const points = set.points
    const self = this.self
    let balance = 0
    let tooltipContent = '<table>'
    points.forEach(function(point){
      let series = point.series
      let seriesName = series.name
      let stack = series.userOptions.stack
      let value = point.y
      let color = point.color
      tooltipContent += `
        <tr>
          <td>
            <strong style='color:${color}'> ${seriesName}:&nbsp</strong>
          </td>
          <td style='text-align: right'>
            ${self.moneyFormat(value)}
          </td>
        </tr>
      `
      if (stack == 0) {
        balance += value 
      } else {
        balance -= value
      }
    })
    tooltipContent += `
      <tr>
        <td>
          <strong>Balance:&nbsp</strong>
        </td>
        <td style='text-align: right'>
          <strong>${self.moneyFormat(balance)}</strong>
        </td>
      </tr>
    </table>`

    return tooltipContent;
  }

  moneyFormat(price) {
    const sign = price >= 0 ? '' : '-'
    const amount = Math.abs(price)
    return `${sign}${this.chartExtras.unit} ${amount.formatMoney(this.chartExtras.precision, this.chartExtras.separator, this.chartExtras.delimiter)}`
  }

  serviceBillingTooltipFormater(set) {
    let self = this.self
    let tooltipContent = `
      <b>${set.x}</b>
      <br>${set.series.name}: ${self.moneyFormat(set.y)}
     `
    return tooltipContent
  }

  defaultTooltipFormater(set) {
    let self = this.self
    let tooltipContent = `
      <b>${set.point.name}</b>
      <br>${set.series.name}: ${set.y}
     `
    return tooltipContent
  }
}
