import BaseController from './base_controller'
import I18n from "i18n-js";

export default class extends BaseController {
  static values = { subcategories: Object, subcategoryName: String }

  connect () {
    const categorySelect = document.getElementById('category_name-hidden-value');
    const subcategorySelect = document.getElementById('subcategory_name-hidden-value')
    const categoryNameInput = document.getElementById('category_name');
    const subcategoryNameInput = document.getElementById('category_sub_name');

    if (subcategorySelect) {
      categoryNameInput.value = categorySelect.value
      subcategoryNameInput.value = subcategorySelect.value
    }
  }

  showFormCategory (selector) {
    const category = selector.target.value
    const categoryForm = document.getElementById('new_category');

    if (category === '0') {
      categoryForm.classList.remove('hide')
    } else {
      categoryForm.classList.add('hide')
    }
  }

  selectCategory (event) {
    const category = event.target?.value
    const subCategorySelect = document.getElementById('subcategory_name-hidden-value');
    const subCategorySelector = document.getElementById('subcategory_name-selector');
    const subcategoryNameButton = document.getElementById('subcategory_name-button');
    const categoryNameInput = document.getElementById('category_name');
    const subcategoryNameInput = document.getElementById('category_sub_name');
    subCategorySelect.removeAttribute('value')
    this.clearOptions(subCategorySelector)

    subCategorySelect.parentElement.getElementsByClassName('selected-value')[0].textContent = I18n.t('views.service_billings.form.none_selected')
    const categoryForm = document.getElementById('new_category');

    if (category === '0') {
      categoryForm.classList.remove('hide')
      subcategoryNameButton.setAttribute('disabled', true)
      this.resetInput();
      categoryNameInput.classList.remove('disabled')
    } else {
      subcategoryNameButton.removeAttribute('disabled')
      categoryNameInput.value = category
      subcategoryNameInput.value = ''
      categoryForm.classList.add('hide')
      this.appendOptions(category, subCategorySelector)
    }
  }

  appendOptions (category, selector) {
    const subCategoriesOptions = this.subcategoriesValue[category];

    if (subCategoriesOptions) {
      for (let subcategory of subCategoriesOptions) {
        this.appendNewOption(selector, subcategory, subcategory)
      }
    }

    this.appendNewOption(selector, I18n.t('views.service_billings.form.other_category'), 0)
  }

  removeOptions (selector) {
    const options = selector.parentElement.getElementsByClassName('dropdown-item-selectable')
      Array.from(options).forEach((option) => {
      option.parentElement.removeChild(option)
    });
  }

  selectSubcategory (selector) {
    const subcategory = selector.target.value
    const categorySelect = document.getElementById('category_name-hidden-value');
    const inputCategoryName = document.getElementById('category_name');
    const subcategoryNameInput = document.getElementById('category_sub_name');
    const categoryForm = document.getElementById('new_category');

    if (subcategory === '0') {
      categoryForm.classList.remove('hide')
      if (categorySelect.value) {
        inputCategoryName.value = categorySelect.value
        inputCategoryName.classList.add('disabled')
      }
      subcategoryNameInput.value = ''
    } else {
      subcategoryNameInput.value = subcategory
      inputCategoryName.value = categorySelect.value
      categoryForm.classList.add('hide')
      inputCategoryName.classList.add('disabled')
    }
  }

  resetInput() {
    const categoryNameInput = document.getElementById('category_name');
    const subcategoryNameInput = document.getElementById('category_sub_name');
    categoryNameInput.value = ''
    subcategoryNameInput.value = ''
  }
}
