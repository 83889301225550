import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({

  image: {
    width: "100%",
    height: "100%"
  }
}));

const CompanyLogo = (props) => {
  const classes = useStyles();

  return (
    <a href="/">
      <img src={"/cf-logo.png"} fetchpriority={"high"}  className={classes.image} {...props} tabIndex={-1} />
    </a>
  );
};

export default CompanyLogo;
