import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    maxWidth: "35em !important",
  },
  marginBottom: {
    marginBottom: "2em",
  },
  continue: {
    paddingBottom: "4em",
    width: "100%",
  },
  publicLink: {
    paddingBottom: "3em",
    width: "100%",
  },
  textfield: {
    "& input:valid + fieldset": {
      borderColor: "black",
    },
    "& input:invalid + fieldset": {
      borderColor: "black",
    },
    "& input:valid:focus + fieldset": {
      padding: "4px !important", // override inline-style
    },
  },
  wizardTitle: {
    color: theme.palette.text.secondary,
    fontFamily: theme.typography.fontFamily,
    fontSize: "32px",
    fontWeight: "bold",
    marginBlockStart: "0.83em",
    marginBlockEnd: "0.83em",
    marginInlineStart: "0px",
    marginInlineEnd: "0px",
  },
  radioContainer: {
    padding: "2em 0",
  },
  radio: {
    "&$checked": {
      color: theme.palette.public.public_btn_bg,
    },
  },
  checkboxContainer: {
    paddingBottom: "2em",
  },
  checked: {},
  horizontalDivisor: {
    borderBottom: "2px solid #eaeaea",
    opacity: "0.6",
    margin: "5% 0%",
  },
  MediaLogo: {
    width: "100%",
  },
  qrPaperButton: {
    padding: "5%",
  },
  fieldsetCompleted: {
    width: "100%",
    borderRadius: "5px",
    border: "1px solid #505CEF",
    boxSizing: "border-box",
    boxShadow: "0px 2px 6px rgba(91, 110, 130, 0.22)",
    color: theme.palette.text.primary,
  },
  fieldset: {
    width: "100%",
    borderRadius: "5px",
    border: "1px solid #A6B3C1",
    boxSizing: "border-box",
    boxShadow: "0px 2px 6px rgba(91, 110, 130, 0.22)",
    color: theme.palette.text.primary,
  },
  label: {
    width: "2em !important",
    textAlign: "center",
    position: "relative",
    fontSize: "1.05rem",
    bottom: "-2px",
  },
  fieldsetError: {
    width: "100%",
    borderRadius: "5px",
    border: "1px solid #f44336",
    boxSizing: "border-box",
    boxShadow: "0px 2px 6px rgba(91, 110, 130, 0.22)",
    color: "#f44336",
  },
  error: {
    color: "#f44336",
    textAlign: "center",
  },
  codeTextfield: {
    fontFamily: "Sharp Sans",
    "& input": {
      width: "2em !important",
    },
    "& input:valid + fieldset": {
      borderColor: "black",
    },
    "& input:invalid + fieldset": {
      borderColor: "black",
    },
    "& input:valid:focus + fieldset": {
      padding: "4px !important", // override inline-style
    },
  },
  info_message: {
    color: theme.palette.text.secondary,
  },
  QrContinue: {
    paddingTop: "3em",
    width: "100%",
  },
  QrCodeIamge: {
    width: "70%",
    margin: "auto",
    display: "block",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  QrMediaLogo: {
    width: "50%",
  },
  wizardSubTitle: {
    color: theme.palette.text.secondary,
    fontFamily: theme.typography.fontFamily,
    fontSize: "14px",
    marginBlockStart: "0.83em",
    marginBlockEnd: "0.83em",
    marginInlineStart: "0px",
    marginInlineEnd: "0px",
  },
  wizardTitle: {
    color: theme.palette.text.secondary,
    fontFamily: theme.typography.fontFamily,
    fontSize: "24px",
    fontWeight: "bold",
    marginBlockStart: "0.83em",
    marginBlockEnd: "0.83em",
    marginInlineStart: "0px",
    marginInlineEnd: "0px",
    textAlign: "center",
  },
  titleBox: {
    marginBottom: "14%",
  },
  textfieldMargin: {
    marginBottom: "20px",
  },
  passwordCriteria: {
    fontFamily: theme.typography.fontFamily,
    display: "flex",
    alignItems: "center",
    gap: "0.5em",
    color: "#232942",
    fontSize: "12px",
    fontWeight: 500,
    letterSpacing: "0.4px",
    lineHeight: "16px",
  },
  passwordCriteriaIcon: {
    color: "#C4C6CF",
    width: "1rem",
    height: "1rem",
    flexShrink: 0,
  },
  passwordCriteriaInvalidIcon: {
    color: "#DC4B5C",
    width: "1rem",
    height: "1rem",
    flexShrink: 0,
  },
  passwordCriteriaValidIcon: {
    color: "#4CBF8C",
    width: "1rem",
    height: "1rem",
    flexShrink: 0,
  },
}));

export default useStyles;
