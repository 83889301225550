import React, { useState, useEffect } from "react";
import StepLayout from "../StepLayout"
import Grid from "@material-ui/core/Grid";
import I18n from "i18n-js";
import { makeStyles } from "@material-ui/core/styles";
import QrReader from 'react-qr-reader'
import PublicBtn from "../../../components/PublicBtn";
import { useMutation } from "@apollo/react-hooks";
import {GLOBAL_STATE, setGlobalState} from "../../../state";
import Typography from "@material-ui/core/Typography";
import {CREATE_PROPERTY_USER_REQUEST, VALIDATE_PROPERTY_QR} from "../../../api";
import WizardTitle from "../WizardTitle";
import useStyles from "./Steps.styles";

function QrPropertySignupStepContent({ previous, next, backdrop }) {
    const classes = useStyles();
    const { createdByOauth, newPropertyRequest, userToken } = GLOBAL_STATE;
    const [showError, setShowError] = useState(false);
    const [error, setError] = useState(I18n.t('modules.register.invalid_code'));
    const [params, setParams] = useState({code: '', communityId: '', propertyId: ''});
    const [valid, setValid] = useState(false);
    const [sendValidateRequest, { data, loading }] = useMutation(VALIDATE_PROPERTY_QR, {
        onCompleted(data) {
            if (data.validatePropertyQr) {
                if (data.validatePropertyQr.isValid) {
                    setGlobalState({
                        code: params.code,
                        communityId: params.communityId,
                        propertyId: params.propertyId
                    });
                    if(createdByOauth || newPropertyRequest || userToken) {
                        setValid(true);
                    } else{
                        next();
                    }
                } else {
                    setShowError(true);
                    backdrop.current.deactivate();
                }
            }
        }
    });

    const handleScan = data => {
        if (data) {
            var urlParams = data.substring(data.indexOf('?') + 1).split('&')
            data = {}
            for(var i in urlParams) {
                data[urlParams[i].split('=')[0]] = urlParams[i].split('=')[1]
            }
            setParams({code: data["code"], propertyId: data["property_id"], communityId: data["community_id"]});
            backdrop.current.activate();
            sendValidateRequest({
                variables: {
                    code: params.code,
                    propertyId: params.propertyId,
                    communityId: params.communityId
                }
            });
        }
    };
    const handleError = err => {
        setShowError(true);
    };

    return (
        <Grid >
            <Grid item container justifyContent="flex-start" alignContent="flex-start" alignItems="flex-start" direction="row">
                <WizardTitle number={3} />
            </Grid>
            <Grid item container justifyContent="center" alignContent="center" alignItems="center">
                <Typography variant="body1" className={classes.marginBottom}>
                    <label>{I18n.t('modules.register.verify_message')}</label>
                </Typography>
                <img className={`${classes.QrMediaLogo}  ${classes.marginBottom}`} src='/verification-image.png' />
                <QrReader
                    delay={300}
                    onError={handleError}
                    onScan={handleScan}
                    style={{ width: '66%' }}
                />
                <Grid container item justifyContent="center" alignContent="center" alignItems="center">
                    <p hidden={!showError}>{error}</p>
                </Grid>
                <div className={classes.QrContinue} >
                    <PublicBtn label={I18n.t('modules.register.back_manual')} onClick={previous} />
                </div>
            </Grid>
        </Grid>)
}

function QrPropertySignupStep({ previous, next, backdrop }) {

    return (
        <StepLayout previous={previous} next={next} numberStepLabel={2} numberStep={3} StepContent={QrPropertySignupStepContent} />
    );
}

export default QrPropertySignupStep