import React, { useRef } from "react";
import StepPreviousActions from './StepPreviousActions'
import I18n from "i18n-js";
import { Grid } from "@material-ui/core";
import CompanyLogo from "../../components/CompanyLogo";
import OnboardingSteps from "../../components/BaseComponents/OnboardingSteps";
import BackdropCF from "../../components/BackdropCF";
import CssBaseline from "@material-ui/core/CssBaseline/CssBaseline";
import {GLOBAL_STATE} from "../../state";
import useStyles from './RegisterPartOwner.styles';

const arraySteps = [
    I18n.t('modules.register.first'),
    I18n.t('modules.register.second'),
    I18n.t('modules.register.third'),
    I18n.t('modules.register.four'),
    I18n.t('modules.register.five')];

function StepLabel(number, actualNumber) {
    const classes = useStyles();
    let class_name = number.actualNumber === number.number ? classes.wizardLabelSelected : classes.wizardLabels;
    return (
        <li className={class_name}> {arraySteps[number.number]} </li>
    );
}

function RenderSteps({number}){
    const { createdByOauth, userToken } = GLOBAL_STATE;
    if(createdByOauth || userToken){
        return(
            <div>
                <StepLabel number={1} actualNumber={number} />
                <StepLabel number={2} actualNumber={number} />
                <StepLabel number={3} actualNumber={number} />
            </div>
        )
    }else{
        return(
            <div>
                <StepLabel number={1} actualNumber={number} />
                <StepLabel number={2} actualNumber={number} />
                <StepLabel number={3} actualNumber={number} />
                <StepLabel number={4} actualNumber={number} />
            </div>
        )
    }
}

function StepLayout({ previous, next, StepContent, numberStepLabel, numberStep }) {
    const classes = useStyles();
    const backdropRef = useRef();
    const { newPropertyRequest } = GLOBAL_STATE;

    return (
        <div className='step step-one'>
            <CssBaseline />
            <BackdropCF ref={backdropRef} />
            <Grid container item xs={12} direction="row" justifyContent="center" className={classes.stepper} >
                <Grid item container justifyContent="center" alignItems="center" xs={1} >
                    <StepPreviousActions previous={previous} next={next} numberStepLabel={numberStepLabel} numberStep={numberStep} />
                </Grid>
                <Grid item container justifyContent="center" alignItems="center" alignContent="center" xs={11} >
                    { newPropertyRequest ? '' : (<OnboardingSteps steps={arraySteps} number={(numberStepLabel - 1)} />)}
                </Grid>
            </Grid>
            <Grid container item direction="row" alignItems="center" md={12} className={classes.twoSectionsRoot} >
                <Grid container item direction="column" md={4} alignItems="center" justifyContent="space-around" className={classes.sidebar}>
                    <Grid container item direction="row" justifyContent="center" >
                        <CompanyLogo className={classes.companyIcon} />
                    </Grid>
                    <Grid container item xs={8} justifyContent="center" className={classes.mdStepCounter} alignItems="center" direction="row" >
                        <Grid item container direction="row" xs={12}>
                            <StepPreviousActions previous={previous} next={next} numberStepLabel={numberStepLabel} numberStep={numberStep} />
                        </Grid>
                        <Grid item container direction="row" justifyContent="center" xs={12}>
                            <ol>
                                { newPropertyRequest ? '' : (<RenderSteps number={numberStepLabel}/>)}
                            </ol>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid className={classes.stepBlock}>
                    <StepContent previous={previous} next={next} backdrop={backdropRef} />
                </Grid>
            </Grid>
        </div >
    )
}

export default StepLayout
