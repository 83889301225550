import { Controller } from 'stimulus'
import I18n from 'i18n-js';

export default class extends Controller {
  // The following example demonstrates how to apply this controller in your views.
  //
  // .form-group{ data: {
  //                       controller: 'input-validation',
  //                       options: {
  //                                   filterType: 'maxCharacters alphanumeric',
  //                                   params: { maxLength: 60 },
  //                                   events: 'input'
  //                                }.to_json
  //                    }
  // }
  //   %label= t('views.property_fine_group.form.general_information.name')
  //   = f.text_field :name, class: 'form-control', id: 'charge_name_txt', data: { 'input-validation-target': 'input' }, required: true, autocomplete: 'off'
  //   %template{ data: { 'input-validation-target': 'warningTemplate' } }
  //     %span.validation-alert.text-danger.bold{ data: { warning: 'WARNING' }}= 'INNERTEXT'
  //   %div.message-container{ data: { 'input-validation-target': 'messageContainer' } }
  //     %span.validation-message= I18n.t('errors.messages.max_characters', maxLength: 60)

  static targets = ['input', 'messageContainer', 'warningTemplate']

  events(name) {
    const eventHash = {
      default: ['input', 'keydown', 'keyup', 'mousedown', 'mouseup', 'select', 'contextmenu', 'drop'],
      input: ['input', 'onpaste'],
      basic: ['keydown', 'keyup', 'onpaste', 'input'],
      focus: ['focusout']
    }
    return(eventHash[name || 'default'])
  }

  connect() {
    if (JSON.parse(this.element.dataset.options).deactivateAutoSubmit) {
      this.deactivateAutoSubmit()
    }
    this.validate()
  }

  validate() {
    let element = this.element
    let options = JSON.parse(element.dataset.options)
    let filterTypes = options.filterType.split(' ')

    this.setInputValidation(function(value) {
      let [filter, regex, result] = [null, '', []]

      filterTypes.forEach((type) => {
        filter = this.filters(type, options.params)
        regex = filter.regex
        if (!regex.test(value)) { result.push({ error: type, message: filter.message }) }
      })

      return result
    }.bind(this));
  }

  setOldSelectionStartEnd(input, selectionStart, selectionEnd) {
    input.oldSelectionStart = selectionStart || input.selectionStart;
    input.oldSelectionEnd = selectionEnd || input.selectionEnd;
  }

  setInputOld(input, setOldSelectionStartEnd, value, selectionStart, selectionEnd) {
    input.oldValue = value || input.value;
    setOldSelectionStartEnd(input, selectionStart, selectionEnd)
  }

  addWarnings(container, template, warnings) {
    let [newWarning, newTimeoutId, timeout] = ['','','']
    let timeouts = container.dataset.timeouts ? JSON.parse(container.dataset.timeouts) : []

    warnings.forEach((warning) => {
      let hideAlert = function(noTimeout = true) {
        container.querySelectorAll(`[data-warning=${warning.error}]`).forEach((span) => span.remove())
        if (noTimeout) {
          timeouts = timeouts.filter((t) => t.error !== warning.error)
          container.dataset.timeouts = JSON.stringify(timeouts)
        }
      }

      newWarning = template.innerHTML
      newWarning = newWarning.replace(/INNERTEXT/, warning.message).replace(/WARNING/, warning.error)
      hideAlert(false);
      container.insertAdjacentHTML('beforeend', newWarning)

      timeout = timeouts.find((t) => t.error === warning.error)
      if (timeout) {
        clearTimeout(timeout.id)
        timeouts = timeouts.filter((t) => t.error !== warning.error)
      }
      newTimeoutId = setTimeout(hideAlert, 5000)
      timeouts.push({ error: warning.error, id: newTimeoutId })
      container.dataset.timeouts = JSON.stringify(timeouts)
    })
  }

  removeWarnings(hasWarningTemplate, container = null, selector = null) {
    // hide traditional warning containers
    $(selector).hide();
    // remove stimulus target oriented containers
    if (hasWarningTemplate) {
      container.querySelectorAll('.validation-alert').forEach((alert) => { alert.remove();})
      container.dataset.timeouts = ''
    }
  }

  setInputValidation(inputValidation) {
    let [input, element] = [this.inputTarget, this.element]
    let hasWarningTemplate, warningTemplate, messageContainer
    if (this.hasWarningTemplateTarget) {
      [hasWarningTemplate, warningTemplate, messageContainer] = [this.hasWarningTemplateTarget, this.warningTemplateTarget, this.messageContainerTarget]
    }

    let [setInputOld, addWarnings, removeWarnings, setOldSelectionStartEnd] = [this.setInputOld, this.addWarnings, this.removeWarnings, this.setOldSelectionStartEnd]
    let options = JSON.parse(element.dataset.options)
    let eventListeners = this.events(options.events)

    this.inputTarget.addEventListener('mouseup', (event) =>{
      setOldSelectionStartEnd(event.currentTarget)
    })

    setInputOld(input, setOldSelectionStartEnd, null, input.value.length, input.value.length)
    eventListeners.forEach(
      function(event) {
        const validation = function(event) {
          let results = inputValidation(this.value)
          if (Array.isArray(results) && !results.length) {
            setInputOld(this, setOldSelectionStartEnd)
            removeWarnings(hasWarningTemplate, messageContainer, options.error)
          } else if (this.hasOwnProperty('oldValue')) {
            this.value = this.oldValue;
            this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
            if(options.error || messageContainer){
              // Set error message below input
              if (!options.custom) {
                $(options.error).text(results.map((result) => result.message).join('. '));
              }
              $(options.error).show();

              if (hasWarningTemplate) { addWarnings(messageContainer, warningTemplate, results) }
            }
          } else {
            this.value = '';
          }
        }
        input.addEventListener(event, validation);
      }
    );
  }

  filters(type, params) {
    // Add your own filterTypes below
    const types = {
      'alphanumeric': function () {
        return ({
          regex: new RegExp('^[-a-zA-ZÀ-ÿ0-9 ´¨`,.:()]*$'),
          message: I18n.t('errors.messages.only_alphanumeric')
        })
      },
      'maxCharacters': function (params) {
        return ({
          regex: new RegExp(`^.{0,${params.maxLength}}$`),
          message: I18n.t('errors.messages.max_characters_reached')
        })
      },
      'regex': function (params) {
        return ({
          regex: new RegExp(params.regex),
          message: I18n.t('errors.messages.regex_invalid')
        })
      }
    }
    // Add your own filterTypes above

    return types[type](params)
  }

  deactivateAutoSubmit() {
    $(window).keydown(function(event){
      if(event.keyCode == 13) {
        event.preventDefault();
        return false;
      }
    });
  }
}
