import React, { useState, useRef, useEffect } from "react";
import StepLayout from "../StepLayout";
import Grid from "@material-ui/core/Grid";
import I18n from "i18n-js";
import Typography from "@material-ui/core/Typography";
import PublicBtn from "../../../components/PublicBtn";
import { TextField } from "@material-ui/core";
import { GLOBAL_STATE, setGlobalState } from "../../../state";
import { useMutation } from "@apollo/react-hooks";
import {
  CREATE_PROPERTY_USER_REQUEST,
  VALIDATE_PROPERTY_QR,
} from "../../../api";
import WizardTitle from "../WizardTitle";
import Link from "@material-ui/core/Link";
import useStyles from "./Steps.styles";

function ManualPropertySignupStepContent({ previous, next, backdrop }) {
  const classes = useStyles();
  const { createdByOauth, communityId, propertyId, res2_redirection } = GLOBAL_STATE;
  const f1 = useRef();
  const f2 = useRef();
  const f3 = useRef();
  const f4 = useRef();
  const f5 = useRef();
  const f6 = useRef();
  const [field1, setfield1] = useState("");
  const [field2, setfield2] = useState("");
  const [field3, setfield3] = useState("");
  const [field4, setfield4] = useState("");
  const [field5, setfield5] = useState("");
  const [field6, setfield6] = useState("");
  const [showError, setShowError] = useState(false);
  const [enableButton, setEnableButton] = useState(false);
  const [error, setError] = useState(I18n.t("modules.register.invalid_code"));

  const [sendValidateRequest, { data, loading }] = useMutation(
    VALIDATE_PROPERTY_QR,
    {
      onCompleted(data) {
        if (data.validatePropertyQr) {
          if (data.validatePropertyQr.isValid) {
            let communityCode = getFullCode();
            setGlobalState({
              code: communityCode.toLowerCase(),
            });
            setShowError(false);
            setEnableButton(true);
            backdrop.current.deactivate();
            if (createdByOauth) {
              backdrop.current.activate();
              window.location.href = res2_redirection ?  "/resident_redirect?propertyRegistry=true" : "/home";
            }
          } else {
            setShowError(true);
            setEnableButton(false);
            setGlobalState({
              code: null,
            });
            backdrop.current.deactivate();
          }
        }
      },
    }
  );

  const [
    sendPropertyRequest,
    { dataPropertyRequest, loadingPropertyRequest, errorPropertyRequest },
  ] = useMutation(CREATE_PROPERTY_USER_REQUEST, {
    onCompleted: (data) => {
      if (data && data.createPropertyUserRequest) {
        window.location.href = res2_redirection ?  "/resident_redirect?propertyRegistry=true" : "/home";
      } else {
        setShowError(true);
      }
    },
    onError(errors) {
      let errorMessages = "";
      errors.graphQLErrors.forEach((error) => {
        errorMessages += error.message;
      });
      setErrorMessage(errorMessages);
      backdrop.current.deactivate();
      showError ? null : setShowError(true);
    },
  });

  function nextStep() {
    const {
      communityId,
      propertyId,
      newPropertyRequest,
      code = null,
      userToken,
    } = GLOBAL_STATE;

    if (createdByOauth || newPropertyRequest || userToken) {
      sendPropertyRequest({
        variables: {
          communityId: communityId,
          propertyId: propertyId,
          code: code,
        },
      });
    } else {
      next();
    }
  }

  useEffect(() => {
    validateCodeField(getFullCode());
  }, [field6]);

  function getFullCode() {
    return (
      field1.toUpperCase() +
      field2.toUpperCase() +
      field3.toUpperCase() +
      field4.toUpperCase() +
      field5.toUpperCase() +
      field6.toUpperCase()
    );
  }

  function fieldsetState() {
    const classes = useStyles();
    let communityCode = getFullCode();
    if (showError) {
      return classes.fieldsetError;
    }
    if (communityCode.length === 6) {
      return classes.fieldset;
    }
    if (communityCode.length !== 6) {
      return classes.fieldsetCompleted;
    }
  }

  function validateCodeField(code) {
    setShowError(false);
    if (code.length === 6) {
      backdrop.current.activate();
      sendValidateRequest({ variables: { code: code.toLowerCase(), propertyId: propertyId, communityId: communityId } });
    } else {
      setEnableButton(false);
    }
  }

  function setCodeValues(index, value, reset = false) {
    if (value !== "" || reset) {
      switch (index) {
        case 1:
          setfield1(value.toUpperCase());
          break;
        case 2:
          setfield2(value.toUpperCase());
          break;
        case 3:
          setfield3(value.toUpperCase());
          break;
        case 4:
          setfield4(value.toUpperCase());
          break;
        case 5:
          setfield5(value.toUpperCase());
          break;
        case 6:
          setfield6(value.toUpperCase());
          break;
      }
    }
  }

  function clearSpecialChars(pastedCode) {
    return pastedCode.replace(/[^\w\s]/gi, "");
  }

  function handlePaste(e) {
    const pastedCode = clearSpecialChars(e.clipboardData.getData("Text")).slice(
      0,
      6
    );
    for (let i = 0; i < pastedCode.length; i++) {
      setCodeValues(i + 1, pastedCode[i].toUpperCase());
    }
  }

  function isBackspace(event) {
    const key = event.keyCode || event.charCode;

    if (key === 8 || key === 46) {
      return true;
    }
  }

  return (
    <Grid>
      <Grid className={classes.marginBottom}>
        <WizardTitle number={3} />
      </Grid>
      <Grid className={classes.marginBottom}>
        <Typography variant="body1" className={classes.infoMessage}>
          <label>{I18n.t("modules.register.verify_message")}</label>
        </Typography>
      </Grid>
      <Grid className={classes.marginBottom}>
        <img className={classes.QrCodeIamge} src="/verification-image-pointed.png" />
      </Grid>
      <Grid className={classes.marginBottom}>
        <fieldset className={fieldsetState()}>
          <legend>
            <Typography variant="caption">
              {I18n.t("modules.register.verify_code")}
            </Typography>
          </legend>
          <Typography variant="caption" className={classes.label}>
            {"B - "}
          </Typography>
          <TextField
            className={classes.codeTextfield}
            inputProps={{
              min: 0,
              maxLength: 1,
              style: { textAlign: "center" },
            }}
            tabIndex={1}
            maxLength={1}
            autoFocus={true}
            onPaste={(event) => {
              handlePaste(event);
            }}
            onChange={(event) => {
              if (event.target.value !== "") {
                setCodeValues(1, event.target.value);
                f2.current.focus();
              }
            }}
            value={field1}
            inputRef={f1}
            onKeyDown={(event) => {
              if (isBackspace(event)) {
                setCodeValues(1, "", true);
              }
            }}
          ></TextField>
          <label> </label>
          <TextField
            className={classes.codeTextfield}
            inputProps={{
              min: 0,
              maxLength: 1,
              style: { textAlign: "center" },
            }}
            maxLength={1}
            value={field2}
            onChange={(event) => {
              if (event.target.value !== "") {
                setCodeValues(2, event.target.value);
                f3.current.focus();
              }
            }}
            inputRef={f2}
            tabIndex={2}
            onKeyDown={(event) => {
              if (isBackspace(event)) {
                setCodeValues(2, "", true);
                f1.current.focus();
              }
            }}
          ></TextField>
          <label> </label>
          <TextField
            className={classes.codeTextfield}
            inputProps={{
              min: 0,
              maxLength: 1,
              style: { textAlign: "center" },
            }}
            maxLength={1}
            value={field3}
            onChange={(event) => {
              if (event.target.value !== "") {
                setCodeValues(3, event.target.value);
                f4.current.focus();
              }
            }}
            inputRef={f3}
            tabIndex={3}
            onKeyDown={(event) => {
              if (isBackspace(event)) {
                setCodeValues(3, "", true);
                f2.current.focus();
              }
            }}
          ></TextField>
          <label> </label>
          <label> - </label>
          <TextField
            className={classes.codeTextfield}
            inputProps={{
              min: 0,
              maxLength: 1,
              style: { textAlign: "center" },
            }}
            maxLength={1}
            value={field4}
            onChange={(event) => {
              if (event.target.value !== "") {
                setCodeValues(4, event.target.value);
                f5.current.focus();
              }
            }}
            inputRef={f4}
            tabIndex={4}
            onKeyDown={(event) => {
              if (isBackspace(event)) {
                setCodeValues(4, "", true);
                f3.current.focus();
              }
            }}
          ></TextField>
          <label> </label>
          <TextField
            className={classes.codeTextfield}
            inputProps={{
              min: 0,
              maxLength: 1,
              style: { textAlign: "center" },
            }}
            maxLength={1}
            value={field5}
            onChange={(event) => {
              if (event.target.value !== "") {
                setCodeValues(5, event.target.value);
                f6.current.focus();
              }
            }}
            inputRef={f5}
            tabIndex={5}
            onKeyDown={(event) => {
              if (isBackspace(event)) {
                setCodeValues(5, "", true);
                f4.current.focus();
              }
            }}
          ></TextField>
          <label> </label>
          <TextField
            className={classes.codeTextfield}
            inputProps={{
              min: 0,
              maxLength: 1,
              style: { textAlign: "center" },
            }}
            maxLength={1}
            value={field6}
            onChange={(event) => {
              if (event.target.value !== "") {
                setCodeValues(6, event.target.value);
              }
            }}
            inputRef={f6}
            tabIndex={6}
            onKeyDown={(event) => {
              if (isBackspace(event)) {
                setCodeValues(6, "", true);
                f5.current.focus();
              }
            }}
          ></TextField>
        </fieldset>
      </Grid>
      <Grid>
        <label className={classes.error} hidden={!showError}>
          {error}
        </label>
      </Grid>
      <Grid className={classes.marginBottom}>
        <PublicBtn
          label={I18n.t("modules.register.next")}
          onClick={nextStep}
          disabled={!enableButton}
          tabIndex={7}
        />
      </Grid>
      <Grid container justifyContent="center" className={classes.marginBottom}>
        <Link href="#" align="center" onClick={nextStep} tabIndex={8}>
          {I18n.t("modules.register.skip_or_next")}
        </Link>
      </Grid>
    </Grid>
  );
}

function ManualPropertySignupStep({ previous, next }) {
  return (
    <StepLayout
      previous={previous}
      next={next}
      numberStepLabel={3}
      numberStep={3}
      StepContent={ManualPropertySignupStepContent}
    />
  );
}

export default ManualPropertySignupStep;