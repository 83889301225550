import { Controller } from 'stimulus';

export default class extends Controller {

  connect(){
    this.modal = document.getElementById('generic-delete-modal');
    this.modalTitle = document.getElementById('modal-body-title');
    this.modalBody = document.getElementById('modal-body-text');
    this.confirmButton = document.getElementById('confirm-button');
  }

  openModal(event){
    const button = event.currentTarget;
    const deleteBody = button.dataset.deleteBody;
    const deleteTitle = button.dataset.deleteTitle;
    const confirmText = button.dataset.confirmText;

    this.modalBody.textContent = deleteBody;
    this.modalTitle.textContent = deleteTitle;
    this.confirmButton.textContent = confirmText;
    this.modal.dataset.childFineFormId = button.dataset.fineId;
  }

  delete(event){
    const childFineFormId = this.modal.dataset.childFineFormId;
    const childElement = document.querySelector(`[data-fine-id="${childFineFormId}"]`)
    childElement.closest('form').submit();
  }
}
